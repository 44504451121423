<template>
  <div class="debt-items">
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="' '">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModalExcel">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'import-debt',
                  params: {
                    mode: 'items',
                  },
                }"
                v-if="isShow(['TRANSACTION_IMPORT_EXCEL'])"
              >
                <span style="color: black">
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Nhập từ Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-form>
              <Autosuggest
                :model="searchStock"
                :suggestions="filteredOptions"
                placeholder="cửa hàng"
                :limit="10"
                @select="onSelected"
                @change="onInputChangeStore"
                :disabled="isDisabled"
              />
            </b-form>
          </b-col>
          <b-col>
            <div class="date-style">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.fromDate"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.toDate"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <b-form>
              <b-form-select
                v-model="apiParams.contactId"
                :options="listInstallments"
                size="sm"
                class="select-style"
              ></b-form-select>
            </b-form>
          </b-col>
          <b-col>
            <b-input
              placeholder="Mã HĐ"
              v-model="apiParams.contractCode"
              size="sm"
            />
          </b-col>
          <b-col>
            <b-input
              placeholder="Chứng từ"
              v-model="apiParams.docId"
              size="sm"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form>
              <b-input
                placeholder="Tìm chính xác SĐT KH"
                v-model="apiParams.customerMobile"
                size="sm"
              />
            </b-form>
          </b-col>
          <b-col>
            <b-form>
              <b-form-select
                v-model="apiParams.isFullPaid"
                :options="optionPaidStatus"
                size="sm"
                class="select-style"
              ></b-form-select>
            </b-form>
          </b-col>
          <b-col>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
            >
              Lọc</b-button
            >
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>

        <!-- LIST DATA -->
        <b-row>
          <b-col md="12" lg="12" sm="12">
            <b-table-simple bordered hover class="tbody-hover">
              <b-thead>
                <b-tr>
                  <b-th class="text-center tdDate">Ngày</b-th>
                  <b-th class="text-center tdStore">Cửa hàng</b-th>
                  <b-th class="text-center tdCustomer">Khách hàng</b-th>
                  <b-th class="text-center tdProductName">Dịch vụ trả góp</b-th>
                  <b-th class="text-center tdProductPrice">Mã hợp đồng</b-th>
                  <b-th class="text-center tdProductPrice">Tiền trả góp</b-th>
                  <b-th class="text-center tdTotalPayment">Đã thanh toán</b-th>
                  <b-th class="text-center tdTotalPayment">Còn lại</b-th>
                  <b-th class="text-center tdTotalAmount"
                    >Tổng tiền hoá đơn</b-th
                  >
                  <b-th class="text-center"></b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-for="bill in mainList.dataset" :key="bill.id">
                <b-tr v-for="(item, index) in bill.payments" :key="index">
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="tdDate"
                    v-show="isShowRow(bill.payments, item)"
                  >
                    {{ formatDate(bill.createdAt) }}
                    <div>
                      <router-link
                        :to="{
                          path: '/bills/update-bill',
                          query: { id: bill.billNumber },
                        }"
                        target="_blank"
                        >{{ bill.billNumber }}
                      </router-link>
                    </div>
                  </b-td>
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="text-left tdStore"
                    v-show="isShowRow(bill.payments, item)"
                  >
                    {{ bill.storeName }}
                  </b-td>
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="text-left tdCenter tdCustomer"
                    v-show="isShowRow(bill.payments, item)"
                  >
                    <b> {{ bill.customerName }}</b>
                    <p>{{ bill.customerMobile }}</p>
                  </b-td>
                  <b-td class="text-left tdProductName">
                    <b> {{ item.paymentName }}</b>
                  </b-td>
                  <b-td class="text-right tdProductPrice">
                    {{ item.paymentCode }}
                  </b-td>
                  <b-td class="text-right tdProductPrice">
                    {{ convertPrice(item.paymentAmount) }}
                  </b-td>
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="text-right tdCenter"
                    v-show="isShowRow(bill.payments, item)"
                    style="width: 10%; color: #3c763d; font-weight: 700"
                  >
                    {{ convertPrice(bill.sumAmountTransactions) }}
                  </b-td>
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="text-right tdCenter"
                    v-show="isShowRow(bill.payments, item)"
                    style="width: 10%; color: #3c763d; font-weight: 700"
                  >
                    {{ convertPrice(bill.sumRemainTransaction) }}
                  </b-td>
                  <b-td
                    :rowspan="bill.payments.length + 1"
                    class="text-right tdCenter"
                    v-show="isShowRow(bill.payments, item)"
                    style="width: 10%; color: #3c763d; font-weight: 700"
                  >
                    {{ convertPrice(bill.totalAmount) }}
                  </b-td>
                  <b-td class="text-center" style="width: 5%">
                    <b-dropdown
                      size="sm"
                      id="dropdown-left"
                      right
                      no-caret
                      v-if="bill.sumRemainTransaction > 0"
                    >
                      <template slot="button-content">
                        <i
                          style="font-size: 1rem; padding-right: 0px"
                          class="flaticon2-settings"
                        ></i>
                      </template>
                      <b-dropdown-item o-caret @click="editModalPayment(bill)">
                        <span style="color: #3f4254; font-size: 12px">
                          <i class="flaticon2-pen" style="font-size: 1rem"></i>
                          &nbsp; Chỉnh sửa
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        o-caret
                        @click="linkToTransaction(bill, item)"
                      >
                        <span style="color: #3f4254; font-size: 12px">
                          <i
                            style="font-size: 1rem"
                            class="far fa-money-bill-alt"
                          ></i>
                          &nbsp; Thanh toán bằng tiền mặt
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <router-link
                          :to="{
                            name: 'transaction',
                            params: {
                              mainModelProp: {
                                contactType: '3',
                                reconciliationDocType: '1',
                                reconciliationDocId: bill.billNumber,
                                type: '3',
                                contactId: item.paymentRefId,
                                contactName: item.paymentName,
                                amount: item.paymentAmount,
                                note: getNoteForPay(bill, item),
                              },
                              mode: 'bank',
                              action: 'add',
                              id: 'new',
                            },
                          }"
                        >
                          <span style="color: #3f4254; font-size: 12px">
                            <i
                              style="font-size: 1rem"
                              class="fas fa-money-check-alt"
                            ></i>
                            &nbsp; Thanh toán bằng CK ngân hàng
                          </span>
                        </router-link>
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <tr>
                  <td colspan="5" class="text-right">
                    <b>Tổng</b>
                  </td>
                  <td class="text-right">
                    <b>
                      {{
                        sumTotalAmount('sumAmountPayments', mainList.dataset)
                      }}</b
                    >
                  </td>
                  <td class="text-right">
                    <b>
                      {{
                        sumTotalAmount(
                          'sumAmountTransactions',
                          mainList.dataset
                        )
                      }}</b
                    >
                  </td>
                  <td class="text-right">
                    <b>
                      {{
                        sumTotalAmount('sumRemainTransaction', mainList.dataset)
                      }}
                    </b>
                  </td>
                  <td class="text-right">
                    <b>
                      {{ sumTotalAmount('totalAmount', mainList.dataset) }}
                    </b>
                  </td>
                  <td></td>
                </tr>
              </b-tfoot>
            </b-table-simple>
            <vcl-table
              v-if="onLoadingList"
              :speed="5"
              :animate="true"
              :columns="11"
            />
          </b-col>
        </b-row>
        <!-- PAGINATION -->
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ mainList.total }}
            </p>
          </b-col>
          <b-col lg="9" md="9" sm="12">
            <b-pagination-nav
              class="customPagination"
              v-show="mainList.pages >= 2"
              :link-gen="linkGen"
              :number-of-pages="mainList.pages"
              use-router
              @change="fetchMainData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1 "
              last-class="page-item-last btn btn-icon btn-sm m-1 "
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <DebtEditModal
      :item="bill"
      :mode="mbPaymentType"
      v-on:refresh="fetchMainData"
    />
    <template>
      <DebtExcel :param="apiParams" :mode="mbPaymentType" />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import _ from 'lodash';
import Loader from '@/view/content/Loader.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import { checkPermissions } from '@/utils/saveDataToLocal';
import { convertPrice } from '@/utils/common';
import DebtEditModal from '@/view/components/debt/DebtEditModal.vue';
import { getSubtractDate, getAddDate, formatDate } from '@/utils/date';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import DebtExcel from '../../components/debt/DebtExcel.vue';

const MIN_RANGE_DATE = getSubtractDate(7);
const MAX_RANGE_DATE = getAddDate();
export default {
  data() {
    return {
      title: 'Công nợ trả góp hóa đơn theo ngày',
      listInstallments: [{ value: null, text: 'Tất cả dịch vụ trả góp' }],
      optionPaidStatus: [
        { value: null, text: 'Công nợ trả góp' },
        { value: 'true', text: 'Hết nợ' },
        { value: 'false', text: 'Còn nợ' },
      ],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      btnDisable: false,
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: null,
        fromDate: MIN_RANGE_DATE,
        toDate: MAX_RANGE_DATE,
        contactId: null,
        contractCode: null,
        docId: null,
        isFullPaid: null,
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptions: [],
      optionsStore: [],
      searchStock: '',
      isDisabled: false,
      bill: {
        billNumber: null,
      },
      mbPaymentType: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    Autosuggest,
    DebtEditModal,
    DebtExcel,
  },
  mounted() {
    window.addEventListener('keyup', this.onFilterKeyEnter);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onFilterKeyEnter);
  },
  created() {
    this.getFillter();
    this.loadBreadcum();
    this.fetchInstallments();
    this.fetchStoreByUser();
    this.onFilter();
  },
  methods: {
    linkToTransaction(bill, item) {
      this.$router.push({
        name: 'transaction',
        params: {
          mainModelProp: {
            contactType: '3',
            reconciliationDocType: '1',
            reconciliationDocId: bill.billNumber,
            type: '1',
            contactId: item.paymentRefId,
            contactName: item.paymentName,
            amount: item.paymentAmount,
            note: this.getNoteForPay(bill, item),
          },
          mode: 'cash',
          action: 'add',
          id: 'new',
        },
      });
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    convertPrice,
    ...getToastInstance(),
    formatDate,
    onFilterKeyEnter(event) {
      if (event.key === 'Enter') {
        this.onFilter();
      }
    },
    linkGen(pageNum) {
      let param = this.getParamFilters();
      param = _.omitBy(param, _.isNil);
      param.page = pageNum || 1;
      return {
        path: '/accounting/debts/items',
        query: param,
      };
    },
    onFilter() {
      this.onChangeUrl();
      this.fetchMainData();
    },
    onChangeUrl() {
      this.$route.query.page = 1;

      let param = this.setFillter();
      param = _.omit(param, ['page', 'pageSize']);
      this.$router.push({
        path: `/accounting/debts/items`,
        query: _.omitBy(param, _.isNil),
      });
    },
    setFillter() {
      const param = this.getParamFilters();

      return param;
    },
    getParamFilters() {
      return {
        page: this.apiParams.page,
        pageSize: this.apiParams.pageSize,
        storeId: this.apiParams.storeId,
        contactId: this.apiParams.contactId,
        fromDate: this.apiParams.fromDate
          ? moment(this.apiParams.fromDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        toDate: this.apiParams.toDate
          ? moment(this.apiParams.toDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
          : null,
        contractCode: this.apiParams.contractCode,
        docId: this.apiParams.docId,
        customerMobile: this.apiParams.customerMobile,
        isFullPaid: this.apiParams.isFullPaid || null,
      };
    },
    getFillter() {
      const query = this.$route.query || null;

      if (!_.isEmpty(query)) {
        const storeId = query.searchStock || null;

        const fromDate = query.fromDate || null;

        const toDate = query.toDate || null;

        const contractCode = query.contractCode || null;

        const docId = query.docId || null;

        const customerMobile = query.customerMobile || null;

        const isFullPaid = query.isFullPaid || null;

        const page = query.page || 1;

        const pageSize = query.pageSize || 10;

        const contactId = query.contactId || null;

        this.apiParams = {
          page,
          pageSize,
          storeId,
          fromDate,
          toDate,
          contractCode,
          docId,
          customerMobile,
          isFullPaid,
          contactId,
        };
      }
    },
    editModalPayment: function (item) {
      this.mbPaymentType = 'items';
      this.bill.billNumber = item.billNumber;
      this.$bvModal.show('md-debt');
    },
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      this.apiParams.type = this.$route.params.mode;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }

      this.onLoadingList = true;
      ApiService.query(cmdUrl.DebtUrl.installments, {
        params: { ...this.apiParams },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          this.onLoadingList = false;
          const message = err.response?.data.message || 'Lỗi';
          this.makeToastFailure(message);
        });
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Kế toán', route: 'accountant' },
        { title: 'Công nợ' },
        { title: this.title },
      ]);
    },
    fetchInstallments: async function () {
      let params = {
        page: 1,
        pageSize: 1000,
      };
      await ApiService.query('/installments', { params }).then((response) => {
        const listData = response.data.data.result;
        _.map(listData, (item) => {
          this.listInstallments.push({
            value: item.id,
            text: item.name,
          });
        });
      });
    },
    sumAmount(key) {
      const outItems = this.mainList.dataset;
      return _.reduce(
        outItems,
        (sum, item) => {
          return sum + parseFloat(item[key]);
        },
        0
      );
    },
    getNoteForPay: function (bill, item) {
      let note = `Nhận thanh toán từ dịch vụ trả góp\n`;
      note += `Mã HĐ: ${bill.billNumber}\n`;
      note += `Dịch vụ: ${item.paymentName}\n`;
      note += `Mã hợp đồng: ${item.paymentCode || ''}`;
      return note;
    },
    isShow(conditions) {
      return checkPermissions(conditions);
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [...filteredData];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.isDisabled = true;
          } else {
            this.optionsStore = [];
            const data = {
              id: null,
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore.push(data);
            stores.map((element) => {
              this.optionsStore.push(element);
            });
            this.filteredOptions = [...this.optionsStore];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
        }
        this.fetchMainData();
      });
    },
    showModalExcel() {
      this.mbPaymentType = 'items';
      this.$bvModal.show('modal-debt-excel');
    },
    sumTotalAmount(field, bills) {
      let sumAmount = _.sumBy(bills, (bill) => bill[field]);
      return convertPrice(sumAmount);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style lang="scss">
.debt-items {
  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  input {
    border: 1px solid #ced4da;
  }

  .date-style {
    display: flex;
    align-items: center;
  }

  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}

.table th,
.table td {
  vertical-align: middle;
}
</style>
